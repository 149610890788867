import { Component, createApp } from 'vue';
import { Router } from 'vue-router';
import { VueQueryPlugin } from 'vue-query';
import { VueCsvImportPlugin } from 'vue-csv-import';
import { createHead } from '@unhead/vue';
import { globalProperties } from './globalProperties';
import { pinia } from '@/stores';
import { setHTTPHeader } from '@/services/http.service';
import AuthService from '@/services/auth.service';
import PageHeader from '@/components/PageHeader.vue';

const token = AuthService.getToken();

if (token) {
  setHTTPHeader({ Authorization: token });
}

const activeCreator = AuthService.getActiveCreator();
if (activeCreator) {
  setHTTPHeader({ 'X-W-C-Creator-Id': activeCreator.id });
}

export const setupEntryPoint = (rootComponent: Component, router: Router) => {
  const app = createApp(rootComponent);

  const head = createHead();

  app.use(head);
  app.use(router);
  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(VueCsvImportPlugin);
  app.config.globalProperties = { ...app.config.globalProperties, ...globalProperties };

  app.component('page-header', PageHeader);

  app.mount('#app');
};
